//cr22 refresh
@import 'overrides/overrides';

@import '../../cremedelamer2_base/scss/cremedelamer2-base-pc';
@import 'liveperson';

.session-idle-popup__content {
  text-align: center;
}

.session-idle-popup__content--header {
  text-transform: uppercase;
  margin-bottom: 15px;
}

.session-idle-popup__content--desc {
  margin-bottom: 45px;
}

// SMACSS/Components/account
@import 'sections/_mixins';
@import 'sections/pc/account/_account';
@import 'sections/pc/checkout/_checkout';
@import 'sections/_all';
@import 'sections/pc/_all';
@import 'sections/social_login';
@import 'sections/account/_account-payment';
@import 'sections/checkout/_checkout-payment';

//Click and collect
@import 'sections/click_and_collect';

//Phone Order
@import 'sections/phone_order';

// One Trust Cookie Styles.
@import '../../cremedelamer2_base/scss/sections/one_trust_cookie/cookie_settings_ot';

// Password strengthening
@import 'sections/account-password-strengthen';

// Order tracking
@import 'sections/customer-care/where-is-my-order';

// Loyalty
@import 'sections/loyalty';

// Appointment Booking
@import 'sections/appointment_booking';

.site-footer-formatter {
  &__term-link {
    vertical-align: middle;
  }
  &__accessibility {
    a {
      line-height: get-line-height(14px, 35px);
    }
  }
}
