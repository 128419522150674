body {
  .site-header {
    &__trustmark-wrapper {
      float: left;
      position: relative;
      top: -18px;
    }
    &__menu-wrapper {
      clear: both;
    }
  }
}

#site-content {
  .spp {
    &__container {
      .after-pay {
        &__spp {
          padding-bottom: 5px;
        }
        &__pay-detail {
          left: 4%;
          top: 8px;
          font-size: 11px;
        }
        &__rate {
          display: inline;
          span {
            font-weight: 100;
          }
        }
      }
    }
  }
}

.site-header {
  &__account-menu-select {
    &-selectBox-dropdown-menu {
      min-width: 146px;
    }
  }
  &.sticky {
    .content {
      .menu {
        &__item {
          font-size: 10px;
          margin: 0 6px;
        }
      }
      .site-header {
        &__utility-right {
          li:nth-child(3) {
            padding: 0 4px;
          }
        }
      }
    }
    .menu {
      &__item {
        &--search {
          .icon--search {
            border-left: none;
          }
        }
      }
    }
  }
}

#index,
#viewcart,
#favorites,
#orders,
#purchases,
#settings,
#payment_info,
#appointments,
#landing {
  a {
    &.selectBox {
      .selectBox-label {
        padding-#{$rdirection}: 27px;
      }
    }
  }
}
