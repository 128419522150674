/* Disconnect button in profile preference page */
#facebook_disconnect input.form-submit {
  margin-left: 0;
  margin: 0;
  float: none;
}
/* Checkout Page Styles */
.checkout__index-content {
  .social-login-section {
    .social-login__terms p {
      margin: 0;
    }
  }
}
/* For displaying the FB Button */
.social-login-section {
  .social-login {
    margin: 0;
    .facebook-button {
      display: none;
    }
    &__container {
      display: block;
      position: relative;
    }
    &__email-opt-in {
      text-align: left;
      padding-bottom: 5px;
    }
    &__terms p {
      text-align: justify;
    }
    &__divider {
      margin: 0;
    }
  }
}
/* Order Confirmation Page */
#confirmation-panel {
  .social-login-section {
    .social-login {
      margin: 0;
      &__divider {
        display: none;
      }
      &__title {
        display: block;
        text-align: left;
      }
      &__info {
        display: block;
        text-align: left;
        @include breakpoint($medium-up) {
          width: 60%;
        }
      }
      &__container {
        margin: 0;
      }
      &__terms {
        display: none;
      }
    }
  }
}
/* GNAV Style */
.site-header__utility {
  .gnav-util__content--account {
    .login {
      .social-login {
        &__container {
          position: unset;
        }
        &__email-opt-in {
          text-align: left;
        }
        .facebook-button.facebook-button-disabled {
          display: none;
        }
        &__terms {
          text-align: justify;
          label {
            display: none;
          }
        }
      }
    }
  }
}
/* GNAV Registration */
.gnav-util--account {
  .registration {
    .social-login {
      &__container {
        position: unset;
      }
      &__email-opt-in {
        text-align: left;
      }
      &__terms {
        text-align: justify;
      }
    }
  }
}
