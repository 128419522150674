// Sign in page
#signin {
  .new-account,
  .return-user {
    form {
      .error_messages {
        text-align: left;
        width: 390px;
        margin: 15px auto;
      }
      .join-loyalty {
        text-align: #{$ldirection};
      }
      .new-account {
        &__section {
          float: #{$ldirection};
        }
      }
    }
  }
  .new-account {
    .social-login__divider {
      .inner {
        top: 67%;
      }
    }
    &__item_content {
      text-align: center;
    }
  }
}
// Profile preferences
#profile_preferences {
  .profile-page__container--form {
    .profile-info__fieldset {
      .field-container {
        span {
          &.form-item {
            display: inline-block;
            margin: 0 20px 0 0;
          }
        }
        .label {
          display: none;
        }
      }
    }
  }
}

//Address book
#settings,
#replenish {
  #colorbox {
    .address-overlay-shipping {
      margin-bottom: 20px;
      .address-form {
        &__header {
          text-align: center;
          text-transform: uppercase;
        }
        &__item {
          width: 50%;
          float: left;
          padding-left: 12px;
          &.left {
            clear: left;
            padding: 0;
          }
          &.full {
            clear: left;
            width: 100%;
            padding: 0;
          }
          label {
            display: block !important;
          }
          input[type='text'],
          input[type='tel'] {
            width: 100%;
            padding: 10px 8px;
          }
          &.address_title_header,
          &.address_title_label {
            width: 100%;
            padding: 0;
            clear: left;
            label {
              display: inline-block;
            }
          }
          &.address_title_label {
            span {
              &.form-item {
                display: inline-block;
                margin-right: 20px;
                margin: 0 20px 0 0;
              }
            }
          }
        }
      }
      fieldset {
        &.qas {
          .qas_container {
            label {
              display: none !important;
              &.address_label {
                display: block;
              }
            }
            input[type='text'] {
              width: 100%;
            }
          }
        }
        &.fs {
          select {
            width: 100%;
          }
        }
        ul {
          &#form--errors--address {
            li {
              color: $color-error;
            }
          }
        }
      }
      .default_ship_container {
        margin-bottom: 15px;
      }
      #qas_search_target {
        .address-form {
          &__address1-container,
          &__address2-container {
            width: 100%;
            padding-left: 0;
          }
          &__state-container,
          &__postal-code-container {
            width: 25%;
            padding-left: 5px;
            margin-right: 0;
            clear: none;
          }
          &__state-container {
            label {
              display: none !important;
            }
          }
          &__address2-container {
            .js-placeholder-mode {
              & + lable {
                opacity: 0 !important;
              }
            }
          }
        }
        .text--form-help {
          display: inline-block;
          margin-bottom: 15px;
        }
      }
    }
    #cboxLoadedContent {
      .address-delete {
        .address-delete__header {
          text-transform: uppercase;
        }
        .button--secondary {
          &.address-delete__link {
            width: 49%;
            float: left;
            margin-right: 30px;
          }
        }
      }
      max-height: 550px;
    }
  }
  .ui-widget-content {
    &.ui-menu {
      height: 12em;
      overflow-y: auto;
    }
    a {
      text-decoration: none;
      border-bottom: 1px solid $color-lightest-grey;
    }
  }
  #address_form_container {
    position: relative;
  }
}

// Order history page
#orders {
  .orders-list {
    &__container {
      .orders-list__item {
        &.active {
          display: inline-block;
        }
      }
    }
  }
}

// Replenishment
.replenishment-page {
  .account-page__header {
    .account-page__title {
      line-height: 1.25;
      padding: 1.3rem 0;
      text-transform: uppercase;
    }
  }
  .replenishment {
    .replenishment__products {
      .products {
        &-header {
          border-bottom: 1px solid $color-lightest-grey;
          margin-bottom: 1em;
          height: 30px;
          .product-header {
            &__info {
              width: 50%;
            }
            &__next-order-date {
              width: 25%;
            }
            &__price {
              width: 12.5%;
              float: right;
            }
          }
        }
        &-list {
          li {
            &.products-list__item {
              border-bottom: 1px solid $color-lightest-grey;
              margin-bottom: 1em;
              padding: 35px 0;
              .product {
                border: 0;
                &__image {
                  width: 12.5%;
                  float: left;
                  padding-right: 1em;
                }
                &__info {
                  width: 37.5%;
                  float: left;
                  padding-right: 1em;
                  padding-left: 0;
                }
                &__next-order-date {
                  width: 25%;
                  float: left;
                  padding-right: 1em;
                }
                &__price {
                  width: 12.5%;
                  float: right;
                  padding-right: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  .replenishment-detail-page {
    width: 82%;
    float: right;
    padding: 0 30px 30px 0;
    &__content {
      .replenishment__products {
        > h5 {
          text-transform: uppercase;
        }
        .product {
          height: 130px;
          &__image {
            width: 12.5%;
            float: left;
            padding-right: 1em;
          }
          &__info {
            width: 37.5%;
            float: left;
            padding-right: 1em;
            padding-left: 30px;
            .product__name {
              a {
                font-size: 15px;
              }
            }
          }
          &__price {
            width: 12.5%;
            float: right;
            padding-right: 0;
          }
        }
      }
      #replenishment_update {
        .shipping-frequency {
          &__info {
            p {
              margin-bottom: 1.5em;
            }
          }
          .frequency-table {
            padding-bottom: 70px;
            height: auto;
            clear: both;
            &__item {
              min-height: 0px;
              height: auto;
            }
            p {
              margin-bottom: 1.5em;
            }
          }
          .shipping-address {
            width: 100%;
            &__header {
              font-size: 15px;
            }
            &__menu {
              .address {
                &-container {
                  width: 33%;
                  float: left;
                }
                &-controls {
                  width: 50%;
                  float: left;
                  .js-choose-address {
                    select {
                      width: 65%;
                      min-width: 65%;
                    }
                  }
                }
              }
            }
          }
          .payment-info {
            &.panel {
              width: 100%;
              padding-top: 20px;
              border-top: 1px solid $color-lightest-grey;
              .payment-info__header {
                font-size: 15px;
              }
              .payment-controls {
                &__method {
                  select {
                    width: 30%;
                    min-width: 30%;
                  }
                }
              }
            }
          }
          .billing-address {
            width: 100%;
            &__header {
              font-size: 15px;
              text-transform: uppercase;
            }
          }
        }
        .panel {
          .form-submit {
            float: right;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .account-overview__profile,
  .account-setting__profile {
    margin-left: 15px;
  }
}

@media (max-width: 768px) {
  .site-footer {
    .site-footer-pc {
      padding: 20px 5%;
    }
  }
}
/* account sign in overlay */
.site-header {
  &__utility {
    .gnav-util {
      &__content {
        &--account {
          height: auto;
          overflow: auto;
        }
      }
    }
  }
}
