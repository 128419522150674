.change-password {
  .password-field {
    &__info {
      @include breakpoint($medium-up) {
        #{$ldirection}: 100%;
        margin-#{$ldirection}: 3%;
        &-checkout {
          #{$ldirection}: 48%;
          -webkit-transform: translateY(13%);
          -moz-transform: translateY(13%);
          -o-transform: translateY(13%);
          transform: translateY(13%);
          &:before {
            top: 50%;
          }
        }
        &-gnav {
          #{$ldirection}: 0;
          display: block !important;
          visibility: visible;
        }
        &:before {
          #{$ldirection}: -5%;
          -webkit-transform: rotate(0);
          -ms-transform: rotate(0);
          -o-transform: rotate(0);
          transform: rotate(0);
        }
      }
    }
  }
  .profile__change-password-overlay {
    .profile__reset-password {
      .profile-info {
        &__item {
          input {
            width: 85%;
          }
          .button {
            width: 37%;
            padding-#{$ldirection}: 15px;
            padding-#{$rdirection}: 15px;
          }
        }
      }
    }
  }
}
