.account_payment {
  /* payment information */
  .payment-info {
    .btn {
      @include btn-style();
      @include breakpoint($small-down) {
        width: 100%;
      }
      font-size: 11px;
      &:hover {
        @include btn-style-hover();
      }
    }
  }
  .payment_book_container {
    @include breakpoint($small-down) {
      padding: 0 10px;
    }
    .payment-info {
      &__default {
        margin-bottom: 25px;
      }
      &__other {
        margin-bottom: 25px;
      }
      &__header {
        font-weight: bold;
      }
    }
    .payment-item {
      tr {
        td {
          &:first-child {
            font-weight: bold;
          }
        }
      }
      a {
        &:hover {
          text-decoration: underline;
        }
        &.default_payment {
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .delete_payment {
        margin-left: 10px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .payment-details {
      margin-bottom: 25px;
      li {
        font-weight: bold;
        &:first-child {
          padding-top: 3px;
        }
        span {
          font-weight: normal;
        }
      }
    }
    .delete_payment {
      margin-left: 10px;
    }
  }
  .payment-delete {
    .payment-delete {
      &__info {
        margin-bottom: 10px;
      }
      &__link {
        margin-right: 10px;
      }
    }
  }
  /* Add/Edit Payment */
  .payment-form {
    @include breakpoint($small-down) {
      padding: 0 10px;
      width: 100%;
    }
    width: 50%;
    .error_messages {
      color: $color-error;
    }
    &__submit {
      @include breakpoint($small-down) {
        width: 100%;
        margin: 5% 0;
      }
      width: 50%;
      margin: 3% 0 0;
    }
  }
}
