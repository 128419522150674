//Welcome popup
#colorbox.welcome15-overlay {
  height: 550px !important;
  #cboxWrapper {
    height: 550px !important;
    #cboxMiddleLeft {
      height: 550px !important;
    }
    #cboxContent {
      height: 550px !important;
      #cboxLoadedContent {
        height: 550px !important;
      }
    }
    #cboxMiddleRight {
      height: 550px !important;
    }
  }
}

.welcome15-overlay {
  #cboxLoadedContent {
    .welcome-offer-popup-wrapper__subheader {
      font-size: 20px;
      margin-top: 53px;
      font-style: normal;
      @include breakpoint($medium-portrait-only) {
        font-size: 17px;
        margin-top: 0;
      }
    }
  }
}

//Landing page
.loyalty__market__status__content {
  .loyalty__market__status__section {
    padding: 10px;
    @include breakpoint($medium-portrait-only) {
      padding: 5px;
      height: 80px;
    }
  }
}

.loyalty__market__status {
  .loyalty__market__status__header {
    &__points {
      font-size: 12px;
    }
    &__title {
      font-size: 15px;
    }
  }
}

.loyalty__market__container {
  .loyalty__market__footer {
    padding-top: 0;
    padding-bottom: 2%;
  }
}

//Cancellation popup
div.loyalty-cancel-overlay#colorbox {
  div#cboxWrapper {
    padding: 0;
    div#cboxContent {
      div#cboxLoadedContent {
        @include breakpoint($small-down) {
          padding: 0;
        }
        background-size: 100% 100%;
        background-image: url('/media/export/cms/loyalty/welcome_popup_banner_mobile.png');
        @include breakpoint($medium-up) {
          background-image: url('/media/export/cms/loyalty/welcome_popup_banner_pc.png');
        }
      }
    }
  }
  .loyalty--popover__cancel--checkbox.loyalty--popover__content--container {
    padding: 35px;
    padding-top: 5px;
    margin: 0 auto;
    @include breakpoint($medium-up) {
      width: 500px;
    }
  }
  .loyalty_cancellation_popup {
    text-align: center;
    color: $white;
    background-size: 100% 100%;
    width: 100%;
    @include breakpoint($small-down) {
      padding-top: 240px;
    }
    .loyalty--popover__header {
      margin-top: 170px;
      width: 100%;
      text-align: center;
      font-weight: 500;
      font-family: 'La Mer Text', serif;
      p {
        margin: 0 0 10px;
        @include breakpoint($small-down) {
          font-size: 20px;
        }
      }
    }
    .loyalty--popover__sub--header {
      width: 100%;
      text-align: center;
      font-weight: 500;
      font-size: 19px;
      font-family: 'La Mer Text', serif;
    }
    .loyalty--popover__contact--us {
      text-align: center;
      margin-top: 75px;
      color: $white;
      @include breakpoint($small-down) {
        width: 100%;
        padding: 5px;
      }
    }
    .loyalty--popover__cancel--option {
      margin: 5px;
      .loyalty--popover__content--checkbox {
        p {
          display: inline;
          text-transform: none;
          font-style: normal;
        }
      }
      .loyalty--popover__cancel--checkbox {
        input[type='checkbox'] ~ label::before {
          height: 15px;
          width: 15px;
        }
      }
    }
    .cancel_loyalty_submit {
      border-radius: 0;
      padding: 20px;
      min-width: 190px;
      margin-top: -140px;
    }
    .loyalty--popover__buttons--retain {
      margin-top: 60px;
      @include breakpoint($small-down) {
        margin-top: 90px;
      }
      color: $white;
    }
    .loyalty--popover__form {
      text-transform: none;
    }
  }
}

//my rewards
#loyalty__panel__offers__sku {
  .loyalty__panel__offers__sku__list-container {
    .loyalty__panel__offers__sku__product-image-container {
      text-align: center;
      .loyalty__panel__offers__sku__product__image {
        @include breakpoint($medium-portrait-only) {
          width: 50%;
        }
      }
    }
    .loyalty__panel__offers__sku__product__data__wrapper {
      text-align: center;
    }
    .loyalty__panel__offers__sku__cta__container {
      text-align: center;
    }
  }
}

@include breakpoint($large-up) {
  .loyalty__panel__offers__list-container {
    .loyalty__panel__offers__offer-data-container {
      min-height: 175px;
    }
  }
}

body#landing {
  @include breakpoint($small-down) {
    padding-top: 0;
  }
}

[data-subtype='Disenroll'],
[data-subtype='Online'] {
  .loyalty__panel__transactions__list__column-activity__transaction-subtype {
    display: none;
  }
}

//footer
.footer {
  .content {
    .menu-ref {
      padding-left: 10%;
    }
  }
}

//terms & conditions
.customer-service__content {
  .margin-first {
    margin-left: 20px;
  }
  .margin-second {
    margin-left: 40px;
  }
}
