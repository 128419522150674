.click-and-collect-method-select {
  float: left;
  width: 100%;
  margin-bottom: 35px;
  @include breakpoint($small-down) {
    width: auto;
    margin-bottom: 20px;
  }
  .radio-button {
    float: left;
    width: 470px;
    @include breakpoint($small-down) {
      width: 100%;
      label::before {
        margin-top: -5px;
      }
    }
  }
  .auspost-logo {
    float: left;
    margin-left: 20px;
    @include breakpoint($small-down) {
      display: none;
    }
  }
  label {
    text-transform: none;
    span {
      display: inline-block;
    }
  }
  .auspost-label {
    font-weight: bold;
    margin-left: 10px;
    @include breakpoint($small-down) {
      margin-left: 0;
      max-width: 85%;
    }
  }
  .auspost-note {
    display: inline-block;
    margin-left: 22px;
    margin-top: 10px;
    @include breakpoint($small-down) {
      margin-left: 0;
    }
  }
  .auspost-address-display {
    p {
      margin: 0;
    }
    margin-bottom: 10px;
  }
  #aupost-root {
    margin: 30px 0 0 -20px;
    @include breakpoint($small-down) {
      margin: 30px 0 0;
    }
  }
}

#colorbox.auspost-overlay {
  .auspost-continue {
    width: 100%;
    .location-selected {
      margin: 20px auto;
      display: block;
      width: 150px;
    }
  }
  @include breakpoint($medium-up) {
    #cboxClose {
      top: 2px;
      right: 9px;
      &:before {
        font-size: 22px;
      }
    }
  }
}
