.checkout {
  #payment-panel {
    .checkout__subtitle {
      text-transform: uppercase;
      margin: 10px 0;
    }
    .form-item {
      &.gift-card-number,
      &.gift-card-pin {
        margin: 0 0 20px 0;
        @include breakpoint($small-down) {
          width: 100%;
        }
        width: 48%;
        display: inline-block;
      }
      &.gift-card-number {
        margin-right: 2%;
        input {
          width: 100%;
        }
      }
      label {
        margin-right: 0;
      }
    }
    .gift-cards {
      border-top: 1px solid $color-light-gray;
      border-bottom: 1px solid $color-light-gray;
      padding-bottom: 10px;
    }
    #checkout_reviewGiftCardOnly {
      padding-bottom: 10px;
    }
    .gift-card-entry-container {
      padding-top: 15px;
      input[data-error] {
        border-color: $color-error;
      }
    }
    .messages {
      padding: 0;
    }
    .payment_container {
      .payment-option {
        @include breakpoint($small-down) {
          width: 100%;
        }
        width: 50%;
        font-size: 13px;
        text-transform: uppercase;
        margin-top: 20px;
      }
    }
    .continue-button-wrapper {
      margin-bottom: 30px;
      text-align: right;
    }
    .payment-edit-content {
      .payment-container {
        .continue-button-wrapper {
          margin: 0;
        }
      }
    }
    .adyen-payment {
      padding-top: 20px;
      input {
        &.chckt-pm__radio-button {
          left: 0px;
          position: relative;
          margin: 6px;
        }
        &.chckt-checkbox {
          left: 0px;
          margin: 4px 10px 4px 0;
          position: relative;
        }
      }
      .chckt-button {
        font-family: $font--neue-haas-unica-pro;
        &:not(.chckt-button--disabled) {
          background-color: $color-dark-gray;
          border-radius: 4px;
          &:hover {
            background-color: $color-green;
          }
        }
      }
      .chckt-pm {
        &__pending-message {
          background-color: $color-dark-gray;
        }
        &__details {
          padding: 0 50px 20px 50px;
          .chckt-form-label {
            margin-right: 0;
            &--exp-date {
              width: 55%;
            }
            &--cvc {
              width: 40%;
            }
          }
          .chckt-input-field--cvc {
            max-width: 100%;
          }
        }
      }
    }
    .payment-panel {
      &__confirm_text {
        margin-bottom: 15px;
      }
    }
  }
}
