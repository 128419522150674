@mixin btn-style() {
  height: auto;
  display: inline-block;
  background: $color-dark-gray;
  color: $color-white;
  border: 1px solid $color-dark-gray;
  border-radius: 4px;
  padding: 13px 15px 14px 15px;
  line-height: 1;
  letter-spacing: 0.05em;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  transition: background 0.3s, color 0.3s, border-color 0.3s;
  text-transform: uppercase;
}

@mixin btn-style-hover() {
  text-decoration: none;
  background: $color-green;
  color: $color-white;
  border-color: $color-green;
}

@mixin order-summary-label-value() {
  width: 16.66667%;
  float: left;
  margin-right: -100%;
  padding-right: 0.9em;
  margin-bottom: 1em;
  font-weight: 700;
}

@mixin order-summary-total() {
  float: left;
  margin-right: -100%;
  margin-bottom: 1em;
}
