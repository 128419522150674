$font1: Arial, Helvetica, sans-serif;
$font2: 'LinotypeDidotRoman', Arial, Helvetica, sans-serif;

div.csr_phone_number {
  display: none;
}

.brand-id-LaMer {
  padding: 1em;
  font-size: 14px;
  font-family: $font1;
  h3 {
    font-family: $font2;
    font-weight: bold;
    font-size: 50px;
    text-decoration: underline;
    margin: 0;
  }
  h4 {
    margin: 0;
    font-size: 2em;
    font-family: $font2;
  }
}

#csr_header {
  top: 1em;
  .csr_table {
    table-layout: auto;
    margin: 0;
    td {
      vertical-align: middle;
      padding: 0 20px;
      border: none;
    }
    #csr_dragger {
      cursor: move;
      background: $color-red;
      border: none;
      width: 40px;
    }
  }
  a {
    display: block;
  }
  #csr_info_box {
    border-right: 1px solid $color-red;
  }
  #csr_registered_user_info,
  #cust_cart {
    border-right: 1px solid $color-red;
    line-height: 14px;
  }
}
