.checkout,
.viewcart {
  .checkout__content {
    .checkout-header {
      position: relative;
      padding: 1em;
      width: 100%;
      text-align: center;
      .checkout-header {
        &__title {
          display: block;
          margin: 10px 0px 5px 0px;
          padding: 0;
          text-transform: uppercase;
          font-size: 36px;
          line-height: 36px;
        }
      }
      .continue-shopping {
        display: none;
      }
    }
    #viewcart-panel {
      .messages {
        padding: 0;
        padding-bottom: 8px;
        color: $color-error;
        background-color: transparent;
        border-width: 0;
        #error_cart\.empty {
          color: $color-black;
        }
      }
      .single-message:first-child {
        padding-top: 5px;
      }
      .cart_item__error--hazmat {
        display: inline-block;
        padding-top: 15px;
      }
      .signin-to-see-cart {
        a {
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    .viewcart-buttons-panel {
      .continue-buttons {
        margin: 20px 0;
        text-align: right;
        float: right;
        width: 40%;
        .go-shopping.btn {
          @include btn-style();
          min-width: 140px;
          width: auto;
          font-size: 11px;
          &:hover {
            @include btn-style-hover();
          }
        }
        .continue-checkout {
          margin-top: 10px;
        }
      }
      &.viewcart-buttons-empty {
        .continue-buttons {
          text-align: left;
          float: left;
        }
      }
    }
    .continue-shopping {
      text-decoration: underline;
    }
    .checkout__panel-title {
      a {
        &#shipping-panel-edit,
        &#registration-panel-edit {
          border: none;
          margin-top: 15px;
          font-weight: bold;
          padding: 0;
          float: left;
          font-size: 20px;
          &:hover {
            background: none;
            border-color: none;
            color: $color-green;
            font-size: 20px;
          }
        }
      }
      &.registration-panel {
        &__title {
          display: inline-block;
        }
      }
    }
    a {
      &#shipping-panel-edit,
      &#registration-panel-edit {
        @include breakpoint($landscape-up) {
          float: #{$rdirection};
        }
        display: inline-block;
        text-transform: uppercase;
        border-radius: 4px;
        padding: 13px 15px 14px 15px;
        line-height: 1;
        font-size: 14px;
        letter-spacing: 0.05em;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
        vertical-align: middle;
        min-width: 140px;
        border: 1px solid $color-dark-gray;
        background: $color-white;
        color: $color-dark-gray;
        border-color: $color-lighter-gray;
        &:hover {
          @include btn-style-hover();
          font-size: 14px;
        }
      }
    }
  }
  #top-viewcart-buttons {
    display: none;
  }
  .panel.guarantee-panel {
    display: block;
    border-bottom: none;
    padding: 1em;
    background: $gray;
    margin-top: 3em;
  }
  .recommended-products-panel {
    display: none;
    &__title {
      font-size: 20px;
    }
    .recommended-item {
      img {
        width: 271px;
        height: 271px;
        @media screen and (max-device-width: 768px) {
          height: auto;
        }
      }
    }
  }
  .guarantee-panel {
    &__title {
      font-size: 20px;
    }
  }
  .right {
    &.checkout__sidebar {
      .guarantee-panel {
        display: none;
      }
      .links-panel,
      .offer-section,
      .need-help-panel,
      .shopping-bag-panel {
        padding: 1em 0 0 0;
        margin: 0 1em;
        header {
          float: left;
        }
        .item-count {
          float: right;
          padding-top: 5px;
        }
      }
      .tab-switcher {
        margin: 0 1em;
      }
      .edit {
        font-size: 15px;
        &#shopping-bag-edit {
          text-transform: uppercase;
          font-size: 11px;
        }
      }
      .panel {
        &.shopping-bag-panel {
          border-top: none;
          margin: 0;
        }
        .product-list {
          overflow: auto;
          max-height: 500px;
          .product {
            width: 100%;
            margin: 0 auto;
            padding: 10px 0px;
            border-spacing: initial;
            &__thumb {
              max-width: 140px;
              margin-right: -100%;
              padding-right: 1em;
              float: left;
              width: 50%;
              clear: none;
            }
            &:last-child {
              border-bottom: none;
            }
            &__desc {
              width: 50%;
              float: right;
              margin-left: 0;
              margin-right: 0;
              padding-right: 0;
              clear: right;
            }
          }
        }
        &#viewcart-panel {
          padding: 0;
          .viewcart-panel {
            &__content {
              .cart-item {
                padding: 10px;
                &__thumb {
                  &.product {
                    &__thumb {
                      margin-right: -100%;
                      padding-right: 1em;
                      width: 50%;
                    }
                  }
                }
                &__desc-info {
                  width: 100%;
                }
                &__price {
                  display: none;
                }
                &__qty {
                  margin-right: -100%;
                  margin-left: 50%;
                  width: 25%;
                  padding-right: 1em;
                  clear: right;
                }
                &__total {
                  width: 50%;
                  float: right;
                  margin-left: 0;
                  margin-right: -10px;
                  clear: none;
                  padding-right: 0;
                  margin-bottom: 0.25em;
                  margin-top: 0.25em;
                  line-height: 13px;
                }
                .cart_item {
                  &__error--hazmat {
                    float: left;
                  }
                }
              }
            }
          }
        }
      }
      .samples-panel,
      .offer-code-panel,
      .guarantee-panel {
        &.panel {
          border-top: none;
        }
      }
      .samples-panel,
      .favorites-panel,
      .past-purchases-panel {
        &.panel {
          padding: 1em 0;
          border-bottom: 1px solid $color-light-gray;
        }
      }
      .offer-section {
        display: inline-block;
        width: 93%;
        border-bottom: 1px solid $color-light-gray;
        #offer-code-panel {
          float: left;
          width: 100%;
          header {
            position: relative;
            margin: 0.5em 0;
            padding: 0;
            width: 100%;
          }
          .offer-code__input {
            width: 60%;
            margin-bottom: 15px;
            float: left;
            input {
              width: 100%;
              height: 47px;
              min-width: 100%;
            }
          }
          .offer-code__submit {
            width: 35%;
            float: right;
            input {
              width: 100%;
              height: 47px;
              min-width: 100%;
            }
          }
          a {
            font-size: 16px;
            line-height: 1.6;
            color: $color-dark-gray;
            border-bottom: 1px solid $color-dark-gray;
            text-decoration: none;
            text-transform: uppercase;
            transition: color 0.3s, border-color 0.3s;
            cursor: pointer;
            letter-spacing: 0.075em;
            &:hover {
              text-decoration: none;
              color: $color-green;
              border-color: $color-green;
            }
          }
          label {
            &.offer-code__byline {
              display: none;
            }
          }
        }
      }
      #need-help-panel,
      #links-panel {
        float: left;
        width: 93%;
        border-bottom: 1px solid $color-light-gray;
      }
      .checkout__panel-content {
        clear: both;
      }
      .offer {
        padding: 0;
        width: 100%;
      }
      &.display-footer {
        display: none;
      }
      #links-panel {
        border-bottom: none;
        .overlay-link {
          &.js-max-purchase-policy {
            border-bottom: 1px solid $color-dark-gray;
            text-transform: uppercase;
          }
        }
      }
      .link:hover {
        color: $color-dark-gray;
        border-color: $color-dark-gray;
      }
    }
    .link {
      border-bottom: transparent;
      margin: 0 0 1em 0;
    }
  }
  .tab-bar {
    display: none;
    li {
      margin-right: 0em;
      font-size: 20px;
      width: 28%;
      &:first-child {
        width: 29%;
      }
      &:last-child {
        width: 42%;
      }
      a {
        color: $color-light-gray;
        background-color: transparent;
        padding: 0;
        &.active {
          background-color: transparent;
          color: $color-dark-gray;
        }
      }
    }
  }
  .recommended-product-items {
    .description {
      .info {
        clear: both;
      }
    }
    .price-range {
      clear: both;
    }
  }
  .overlay-link,
  .link a {
    text-decoration: none;
    &:hover {
      color: $color-green;
      border-color: $color-green;
      text-decoration: none;
    }
  }
  .overlay-link {
    font-size: 16px;
    line-height: 1.6;
    color: $color-dark-gray;
    border-bottom: 1px solid $color-dark-gray;
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.3s, border-color 0.3s;
    cursor: pointer;
    letter-spacing: 0.075em;
    &.js-max-purchase-policy {
      text-transform: none;
      border-bottom: dotted 1px;
    }
  }
  #registration-panel {
    clear: both;
    padding-bottom: 10px;
    &.collapsed {
      padding-bottom: 0;
      header {
        padding: 0;
        border-top: none;
        border-bottom: none;
      }
    }
    header {
      margin: auto;
      padding: 15px 0 0 0;
      border-top: 1px solid $color-light-gray;
      border-bottom: 1px solid $color-light-gray;
      clear: none;
    }
    #checkout_registration_panel {
      .checkout-registration {
        .form-item {
          &.password,
          .checkout-password-label,
          &.email-address {
            margin-bottom: 10px;
          }
          &.password,
          &.text {
            input {
              width: 50%;
            }
          }
          &.text {
            margin-bottom: 30px;
          }
          .checkout-password-label {
            display: none;
          }
        }
      }
      .create-account {
        &.button-wrapper {
          text-align: left;
          margin-top: 20px;
          input {
            width: 50%;
          }
        }
      }
    }
    .checkout-registration {
      .example-user-email {
        font-weight: 700;
      }
    }
    .how-we-will-use-container {
      margin-bottom: 10px;
      float: #{$ldirection};
      @include breakpoint($landscape-up) {
        float: #{$rdirection};
      }
      .how-will-we-use {
        ul {
          list-style: disc;
          margin-left: 15px;
        }
        .checkout__subtitle,
        .pre {
          margin-left: 20px;
        }
      }
    }
  }
  .generic-helplinks-overlay {
    &-shippinginfo,
    &-paymentinfo,
    &-returns-policy,
    &-purchase-policy {
      .customer-service {
        margin: 0;
        padding: 0;
        &__navigation,
        &_anchor,
        &__title,
        &__contact_info {
          display: none;
        }
        &__content {
          width: 100%;
        }
      }
      #cboxLoadedContent {
        max-height: 500px;
        overflow: auto;
      }
    }
  }
  .overlay-addclass-generic-helplinks-overlay {
    &-shippinginfo,
    &-paymentinfo,
    &-returns-policy,
    &-purchase-policy {
      width: 630px;
      height: 550px;
    }
  }
  #colorbox {
    &.generic-helplinks-overlay {
      &-shippinginfo,
      &-paymentinfo,
      &-returns-policy,
      &-purchase-policy {
        width: 630px;
        height: 550px;
        max-height: 560px;
      }
    }
  }
}

.btn:hover {
  @include btn-style-hover();
}

body {
  &#viewcart {
    #recommended-products-panel {
      .recommended-products-panel__content {
        @media screen and (max-device-width: 768px) {
          position: relative;
          padding-bottom: 50px;
        }
        .recommended-product-items {
          .recommended-item {
            .description {
              width: 100%;
              margin: 0 15%;
            }
            a {
              text-decoration: none;
            }
            .quickshop {
              position: relative;
              margin: 20px 15% 0;
              @media screen and (max-device-width: 768px) {
                position: absolute;
                bottom: 0;
                margin: 0 3%;
              }
            }
          }
          .price-range {
            margin: 0 15% 5% 15%;
          }
          .addtobag {
            margin: 0 15%;
          }
        }
      }
    }
    html.js & {
      .tab-pane {
        opacity: 1;
        height: auto;
        header {
          display: block;
        }
      }
    }
    // BOGO OfferCode Styles
    .bogo-offer {
      .cart-item {
        &__replenishment,
        &__remove-form,
        &__add-to-favorites {
          display: none;
        }
      }
    }
  }
  &#index {
    &.checkout {
      #need-help-panel {
        display: block;
      }
    }
  }
}

.checkout__panel-title {
  line-height: 36px;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 0.5em;
  padding: 0;
  display: block;
  cursor: pointer;
  font-size: 20px;
  .toggle-icon {
    float: right;
    margin-top: 5px;
    &.icon--minus {
      @include icon('minus');
      margin-top: 0px;
    }
  }
}

#recommended-products-panel,
#bottom-viewcart-buttons,
#samples-pane,
.samples-overlay,
.orders-page__content {
  .btn {
    @include btn-style();
    min-width: 140px;
    width: auto;
    font-size: 11px;
    &:hover {
      @include btn-style-hover();
    }
    @media screen and (max-device-width: 768px) {
      padding: 13px 0 14px;
      min-width: 115px;
    }
  }
}

#bottom-viewcart-buttons {
  .btn {
    &.paypal-checkout {
      width: 25%;
      padding: 6px 16px;
      margin-top: 12px;
      border: 1px solid $color-light-gray;
      background: $color-white;
      display: inline-block;
      height: 40%;
    }
  }
}

//Viewcart panel
#viewcart-panel {
  overflow: hidden;
  position: relative;
  padding: 0px 0px 10px 0px;
  .viewcart-panel__content {
    .cart-header__title {
      padding: 0.8em 0;
      float: left;
      box-sizing: border-box;
      text-transform: uppercase;
      font-weight: 700;
      &--products {
        width: 50%;
        padding-left: 1.5em;
      }
      &--price {
        width: 18%;
        text-align: left;
        padding-left: 0;
      }
      &--qty {
        width: 12%;
        text-align: left;
        padding-left: 0;
      }
      &--total {
        width: 20%;
        float: right;
        text-align: center;
        padding-left: 0;
        box-sizing: border-box;
        text-transform: uppercase;
        font-weight: 700;
      }
    }
    .cart-item {
      padding: 28px 0;
      position: relative;
      margin: 0;
      box-sizing: border-box;
      border-top: 1px solid $color-lightest-grey;
      &__size {
        display: inline-flex;
        width: 100%;
      }
      &__price {
        text-align: left;
        float: left;
        width: 18%;
      }
      &__qty {
        width: 13%;
        margin: 0;
        text-align: left;
        float: left;
        .selectBox-dropdown {
          min-width: 70px;
        }
        .qty-label {
          display: none;
        }
      }
      &__total {
        width: 17%;
        text-align: right;
        float: right;
        font-weight: 700;
        padding-right: 5.4em;
      }
      .cart-item {
        &__remove-form {
          .remove_link {
            border-bottom: 1px solid $color-dark-gray;
            text-transform: uppercase;
            font-size: 11px;
            text-decoration: none;
          }
        }
      }
      .remove-sample {
        border-bottom: 1px solid $color-dark-gray;
        text-transform: uppercase;
        font-size: 11px;
        text-decoration: none;
      }
    }
    .cart-item__thumb {
      padding: 0;
      float: left;
      width: 15%;
      margin-right: 1%;
    }
    .cart-item__desc-info {
      float: left;
      position: relative;
      width: 34%;
      margin-bottom: 0;
      padding-right: 1%;
      .cart-item__price {
        display: none;
      }
      .cart-item__remove-form {
        .cart-item__add-to-favorites {
          display: none;
        }
      }
      .replenishment {
        &.cart-item__replenishment {
          float: none;
          width: 100%;
          padding-top: 20px;
          position: relative;
        }
        .replenishment__description {
          margin-bottom: 0.3em;
          a {
            font-size: 12px;
          }
        }
      }
      .product {
        &_name,
        &_subname {
          a {
            text-decoration: none;
          }
        }
      }
    }
  }
}
/* order summary panel */
#order-summary-panel {
  padding-top: 10px;
  header {
    border-bottom: 2px solid $color-lightest-grey;
    margin: 0 0 16px 0;
  }
  .order-summary {
    &__total-label,
    &__total-value {
      border-top: 1px solid $color-lightest-grey;
      border-bottom: 1px solid $color-lightest-grey;
    }
    &__content {
      .label {
        @include order-summary-label-value();
        margin-left: 16.66667%;
        clear: both;
        text-align: left;
        &.total {
          @include order-summary-total();
          width: 66.66667%;
          margin-left: 16.66667%;
          padding: 1em 1em 1em 0;
          clear: both;
          text-align: left;
        }
      }
      .value {
        @include order-summary-label-value();
        width: 16.66667%;
        margin-left: 75%;
        clear: none;
        text-align: right;
        &.total {
          @include order-summary-total();
          margin-left: 75%;
          clear: none;
          padding: 1em 0.9em 1em 0;
        }
      }
      .content {
        width: 25%;
        float: left;
        margin-right: -100%;
        margin-left: 41.66667%;
        clear: none;
        padding-right: 1em;
      }
    }
  }
  .ship-method {
    width: 100%;
    margin-bottom: 0;
  }
  #index & {
    .label {
      width: 66.66667%;
      float: left;
      margin-right: -100%;
      padding-right: 1em;
      clear: both;
      margin-left: 0;
      font-weight: initial;
      &.total {
        font-weight: 700;
      }
    }
    .value {
      width: 33.33333%;
      float: right;
      margin-left: 0;
      margin-right: 0;
      clear: none;
      padding-right: 0;
      text-align: right;
    }
    .order-summary {
      &__total-label,
      &__total-value {
        border-top: none;
      }
    }
    .content {
      width: 100%;
      float: right;
      margin-left: 0;
      margin-right: 0;
      padding-right: 0;
      clear: both;
      select {
        width: 100%;
      }
    }
  }
}
/* order summary panel */

/* samples */
.samples {
  position: relative;
}

.samples-overlay {
  .samples-panel {
    .product-list {
      overflow: hidden;
      li {
        &.product {
          height: 415px;
          position: relative;
          width: 28%;
          margin: 0 2.5%;
          padding: 0 5px;
          .details {
            .skus {
              margin-top: 20px;
              position: absolute;
              bottom: 0;
              margin-left: 8%;
              width: 80%;
              .sample-select-button {
                width: 100%;
              }
            }
          }
        }
        &:nth-child(4n + 1) {
          clear: none;
        }
      }
    }
  }
  .samples-buttons {
    text-align: center;
    margin-top: 30px;
  }
}
/* samples */

/* favorites-panel */
#favorites-panel,
#past-purchases-panel {
  .product-img {
    max-width: 140px;
  }
  .add-to-cart {
    margin-top: 10px;
  }
  ul {
    &.product-list {
      .product {
        height: auto;
      }
    }
  }
}
/* favorites-panel */

// Checkout signIn
#index {
  .messages {
    color: $color-error;
    background-color: transparent;
    border-width: 0;
    .single-message {
      color: $color-error;
    }
  }
  .form-item {
    .invalid_marker {
      position: absolute;
      right: 18px;
      bottom: 12px;
      color: $color-error;
    }
    &.title {
      .invalid_marker {
        display: none;
      }
    }
  }
  .ui-autocomplete {
    &.ui-menu {
      height: 12em;
      overflow-y: auto;
    }
  }
  #sign-in-panel,
  .sign-in-panel {
    border-bottom: 1px solid $color-lightest-grey;
    padding: 20px 0;
    .checkout {
      &__new-account,
      &__return-user {
        width: 48%;
        display: inline-block;
        vertical-align: top;
        @include breakpoint($tablet-only) {
          width: 47%;
        }
        .checkout__subtitle {
          text-transform: uppercase;
          margin: 10px 0;
        }
        input[type='email'],
        input[type='password'] {
          width: 90%;
        }
        input[type='submit'] {
          margin: 15px 0;
        }
      }
    }
    #new-account {
      &.checkout__new-account {
        border-right: 1px solid $color-lightest-grey;
        margin-right: 20px;
      }
    }
    .messages {
      padding: 0;
      padding-bottom: 8px;
      [class*='error_offer_criteria_'] {
        display: none;
      }
      .single-message {
        padding-bottom: 10px;
      }
    }
  }
  &.active-panel-signin {
    max-width: 100%;
    .checkout__content {
      @include breakpoint($medium-up) {
        width: 100%;
      }
      .checkout-header {
        display: none;
      }
      .sign-in-panel {
        .signin-panel {
          &__title {
            display: none;
          }
        }
      }
    }
    .checkout {
      .checkout__content {
        section {
          &.shipping-panel,
          &.payment-panel {
            &.collapsed {
              display: none;
            }
          }
          &.sign-in-paypal-panel {
            display: none;
          }
        }
      }
    }
  }
  &.active-panel-shipping {
    .ui-autocomplete {
      margin-top: -39px;
    }
  }
  &.checkout {
    .checkout__content {
      section {
        &.shipping-panel,
        &.payment-panel,
        &.registration-panel {
          border-bottom: 1px solid $color-lightest-grey;
          margin-top: 15px;
          &.collapsed {
            display: block;
            color: $color-light-gray;
            .checkout {
              &__panel-title {
                cursor: default;
              }
            }
          }
          &.finished {
            border-bottom: none;
          }
        }
      }
    }
    .checkout__sidebar {
      display: none;
    }
    max-width: none;
    padding: 0;
    &.active-panel-payment,
    &.active-panel-shipping {
      .checkout-header {
        display: none;
      }
    }
  }
  #shipping-panel {
    margin-top: 15px;
    .checkout__subtitle {
      text-transform: uppercase;
      margin-top: 15px;
      &.gift-message__title,
      &.email-and-sms-promotions__subtitle {
        margin-top: 0px;
      }
    }
    .edit-controls {
      clear: both;
      overflow: hidden;
    }
    &-edit {
      text-transform: uppercase;
      text-decoration: none;
    }
    .shipping-panel__title {
      display: inline-block;
      margin-right: 10px;
    }
    .messages {
      padding: 0;
    }
    .shipping-address,
    .new-address {
      .form-item {
        margin: 0 0 20px 0;
      }
    }
    #shipping-address-display,
    #gift-options,
    #billing-address-display {
      float: left;
      width: 33%;
      padding-right: 2%;
      word-wrap: break-word;
    }
    .phones {
      .form-item {
        width: 50%;
        display: inline-block;
        &.phone-1 {
          float: right;
          input[type='tel'] {
            width: 96%;
            margin-left: 4%;
          }
        }
      }
    }
    .new-address {
      .billing-address-form-content {
        margin-top: 10px;
        > .radio {
          display: block;
          margin-bottom: 10px;
          font-size: 13px;
          text-transform: uppercase;
        }
      }
    }
    .billing-address-form-content {
      > label {
        display: block;
        margin-bottom: 15px;
      }
    }
    .continue-button-wrapper {
      margin-top: 20px;
      margin-bottom: 20px;
      text-align: right;
    }
    form {
      .title-fields {
        margin-bottom: 15px;
        .label-content {
          display: block;
          margin-bottom: 10px;
        }
        .radio {
          margin-right: 15px;
          margin-bottom: 0;
          display: inline-block;
        }
      }
      input[type='text'],
      input[type='tel'] {
        width: 100%;
      }
      .add-address {
        .required-text {
          margin: 15px 0;
        }
      }
      .shipping-address-display,
      .edit-shipping-radio {
        width: 49%;
        display: inline-block;
        vertical-align: top;
      }
      .edit-shipping-radio {
        label {
          margin-right: 30px;
        }
      }
      .form-item {
        &.first-name,
        &.last-name {
          width: 49%;
          display: inline-block;
        }
        &.last-name {
          float: right;
        }
        label {
          &.error {
            .label-content {
              color: $color-error;
            }
          }
        }
      }
      .city-state-zip {
        .form-item {
          display: inline-block;
          margin-right: 1.5%;
          &.city {
            width: 32%;
          }
          &.state {
            width: 34%;
            select {
              width: 100%;
            }
          }
          &.postal-code {
            width: 30%;
            margin-right: 0;
            float: right;
          }
        }
      }
      a {
        &.selectBox {
          width: 100%;
          height: 47px;
          margin-top: -4px;
          .selectBox-label {
            padding: 5px 15px;
            font-size: 13px;
          }
          .selectBox-arrow {
            top: 4px;
          }
        }
      }
    }
    .gift-options__content {
      .sub-section {
        margin-bottom: 10px;
        .card-message-to,
        .card-message-from {
          width: 49%;
          display: inline-block;
          input[data-error] {
            border: solid 1px $color-error;
          }
          input {
            &::placeholder {
              text-transform: initial;
              font-family: $font--neue-haas-unica-pro, $font--sans;
              letter-spacing: 0;
              font-size: 1em;
              color: $color-medium-gray;
            }
          }
        }
        .card-message-to {
          margin-right: 1%;
        }
        .card-message {
          textarea {
            width: 100%;
          }
          textarea[data-error] {
            border: solid 1px $color-error;
          }
        }
      }
      .gift-options {
        > img {
          display: none;
        }
      }
    }
    &.adpl {
      input[type='radio'] {
        &:required {
          &:invalid {
            &:not(:checked) {
              &.checkedEmpty {
                & + label {
                  span {
                    color: $color-error;
                  }
                }
              }
            }
          }
        }
      }
    }
    &.finished {
      display: inline-block;
      width: 100%;
    }
  }
}

#viewcart,
#index.checkout {
  .site-footer {
    &__bottom,
    &-pc {
      display: none;
    }
    &__bottom-condensed {
      display: block;
      .divider-pc {
        display: none;
      }
    }
    &__ack {
      display: none;
    }
  }
}
/* order history */
#orders,
#order_detail {
  .orders-list,
  .order-details {
    &__container {
      .order-products {
        .cart-item {
          &__products {
            width: 30%;
          }
          &__total {
            width: 20%;
          }
        }
      }
      .order-details {
        .offer_container {
          .offer {
            width: 70%;
          }
        }
      }
    }
  }
}
/* Order Confirmation */
#confirm {
  &.checkout {
    max-width: none;
    padding: 0;
    section {
      &#confirmation-panel {
        .checkout-header {
          width: 60%;
          margin-left: 20px;
          margin-top: 20px;
          text-align: center;
          margin: 0.5em 0;
          .confirmation-panel {
            &__title {
              font-size: 20px;
              text-transform: uppercase;
            }
          }
        }
        .confirmation-panel {
          &__content {
            text-align: center;
            width: 64%;
            background-color: $color-white-smoke;
            padding: 15px;
            margin-bottom: 20px;
            font-weight: 700;
            .confirmation-panel {
              &__order-number {
                a {
                  text-decoration: none;
                }
                span {
                  &#confirm_trans_order_id {
                    cursor: pointer;
                    color: $color-green;
                    border-bottom: none;
                  }
                }
              }
            }
          }
          &__actions {
            width: 60%;
            margin-left: 0;
            .receipt-print-btn {
              @include btn-style();
              width: 50%;
              font-size: 11px;
              &:hover {
                @include btn-style-hover();
              }
            }
          }
        }
      }
    }
    .checkout {
      &__sidebar {
        float: right;
        &:after {
          width: 32.6%;
          left: 66.2%;
          content: '';
          background-color: $gray;
          height: 75%;
          position: absolute;
          top: 45px;
          display: block;
          z-index: -1;
        }
        .need-help-panel {
          border-top: none;
          .links_list {
            a {
              text-decoration: underline;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
        .panel {
          &.need-help-panel,
          &.links-panel {
            position: relative;
          }
        }
        @include breakpoint($medium-only) {
          width: 33.3333333333%;
        }
      }
      &__content {
        .sign-in-confirmation-panel {
          header {
            border-top: 1px solid $color-white-smoke;
            border-bottom: 1px solid $color-white-smoke;
            padding: 20px 0 5px 0;
          }
          &__content {
            .email-address {
              .email {
                font-weight: 700;
              }
            }
            .form-item {
              margin-bottom: 10px;
              &.password,
              &.submit {
                input {
                  width: 50%;
                }
              }
            }
          }
        }
        #registration-panel {
          .show-password {
            display: none;
          }
          .checkout-password-label {
            display: none;
          }
          .email-address,
          .accepted-privacy-policy {
            margin-bottom: 10px;
          }
        }
      }
    }
    .overlay-link {
      text-transform: initial;
      letter-spacing: normal;
      font-size: 15px;
    }
  }
  .checkout-registration {
    input[type='password'] {
      width: 50%;
    }
  }
}

.wp-samples-landing-panel,
.wp-samples-panel {
  .checkout__panel-title,
  .offer-samples {
    text-align: center;
  }
  .samples-buttons {
    &.bottom {
      margin: 0px 15px 15px 10px;
      .add-cart {
        float: right;
      }
    }
  }
  .product-list {
    .product {
      &:nth-child(3n + 1) {
        clear: both;
      }
      .sku {
        left: 0;
        bottom: 0;
        right: 0;
        margin: 10px auto;
        position: absolute;
      }
    }
  }
}

.wp-samples-panel {
  .return-link {
    display: none;
  }
}

.offerspick-action-buttons {
  margin: 0px 20px 5px 20px;
  .edit-cart {
    width: 50%;
    float: left;
  }
  .continue-buttons {
    .continue-checkout {
      width: auto;
      height: auto;
      line-height: 10px;
      margin-bottom: 10px;
      font-size: 11px;
      float: right;
    }
  }
}

/* loyalty points panel */
section.checkout-panel {
  &--loyalty-points {
    float: #{$ldirection};
  }
  &--loyalty-join {
    width: 64%;
    padding: 1em 0;
    margin: 1em 0;
    border-top: 1px solid $color-light-gray;
  }
}
