/* loading icon stylings */
.loading {
  &.fade-in {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 25% 0;
    text-align: center;
    z-index: 9999;
    background: $color-white;
    opacity: 0.7;
  }
}

.messages {
  padding: 0;
  padding-bottom: 8px;
  color: $color-error;
  background-color: transparent;
  border-width: 0;
}

select::-ms-expand {
  display: none;
}

#index.checkout,
#samples {
  .mobile-checkout-back {
    margin-left: 15px;
    display: block;
    top: 12px;
    position: relative;
    cursor: pointer;
    .icon {
      height: 27px;
      width: 16.8px;
      font-size: 27px;
    }
  }
}

#index {
  &.checkout {
    .checkout__content {
      section {
        &.shipping-panel,
        &.payment-panel,
        &.registration-panel {
          border-bottom: 1px solid $color-lightest-grey;
          margin-top: 15px;
          &.collapsed {
            display: block;
            color: $color-light-gray;
            .checkout {
              &__panel-title {
                cursor: default;
              }
            }
          }
          &.finished {
            border-bottom: none;
          }
        }
      }
    }
    .active-panel-payment,
    .active-panel-shipping {
      .checkout-header {
        &__title {
          display: none;
        }
      }
    }
  }
}

#index.checkout,
#settings {
  input,
  select {
    &:required {
      &:invalid {
        &.empty {
          &.checkedEmpty {
            border-color: $color-error;
          }
        }
        &:not(:checked) {
          &.checkedEmpty {
            border-color: $color-error;
            & + a.selectBox {
              border-color: $color-error;
            }
          }
        }
      }
    }
  }
}

.checkout {
  #shipping-panel,
  .payment-edit-content,
  .checkout-registration {
    input[type='text'],
    input[type='tel'],
    input[name='PASSWORD2'],
    input[type='password'] {
      & + label:before {
        content: attr(data-required) attr(placeholder);
      }
    }
    .phone-1 {
      input[type='tel'] {
        & + label:before {
          margin-left: 8%;
        }
      }
    }
  }
  .checkout-registration {
    input[type='password'] {
      & + label:before {
        content: attr(placeholder);
      }
    }
  }
  #completed_checkout_registration {
    .create-account {
      input[type='submit'] {
        width: 50%;
      }
    }
    .how-will-we-use {
      h3 {
        margin-bottom: 10px;
      }
    }
  }
  #sticky-checkout-button {
    .btn {
      &.disabled-button {
        background-color: $color-gray-darker;
      }
    }
  }
}

.panel {
  input,
  select {
    &:required {
      &.error,
      &:invalid {
        &.empty,
        &:empty,
        &:not(:checked) {
          border-color: $color-lighter-gray;
        }
      }
      &.error[value=''],
      &:invalid[value=''] {
        border-color: $color-lighter-gray;
      }
    }
  }
  &.payment-form {
    input[type='tel'],
    input[type='text'] {
      &.is-filled-in {
        &:required {
          &:invalid {
            &::-webkit-input-placeholder {
              color: $color-light-gray;
            }
          }
        }
      }
    }
  }
}

.sticky-add-to-bag {
  &.sticky {
    .sticky-add-to-bag {
      &--inner {
        @include breakpoint($sticky-breakpoint) {
          padding: 0 20px 22px 20px;
        }
      }
    }
  }
  &_live-chat {
    @include breakpoint($sticky-breakpoint) {
      position: relative;
      float: left;
      margin-top: 3px;
      margin-right: 20px;
      margin-bottom: 90px;
    }
  }
  &live-chat_disc {
    @include breakpoint($sticky-breakpoint) {
      position: absolute;
      top: 10px;
      left: 20px;
      color: $color-salmon;
      font-size: 45px;
      height: 13px;
      line-height: 13px;
    }
  }
}

.waitlist-form {
  &__container {
    .waitlist-form {
      &__email {
        margin-bottom: 10px;
      }
    }
  }
  &__header {
    font-size: 24px;
  }
}

.adpl {
  input[type='email'],
  input[type='tel'],
  input[type='password'],
  input[type='text'] {
    &.error {
      color: $color-error !important;
      border-color: $color-error !important;
    }
  }
  input[type='email'],
  input[type='tel'],
  input[type='password'],
  input[type='text'],
  select {
    & + label:before {
      content: attr(data-required) attr(placeholder);
    }
    &.js-label-mode {
      & + label:before {
        content: attr(data-required) attr(placeholder);
      }
    }
  }
}
/* Search box */
.esearch-nav {
  &__animation-wrapper {
    &.active {
      min-height: 662px;
    }
  }
}

body {
  .product-shade-picker {
    &__smoosh {
      &-img {
        display: none;
      }
    }
  }
  .ff-results-share {
    &__cta-social-media {
      .ff-results-share {
        &__twitter_icon {
          display: none;
        }
        &__facebook_icon {
          margin-left: 25px;
        }
      }
    }
  }
  &.section-skincare-subscription {
    .content-block {
      &-collapsible__cta {
        .link {
          @include btn-style();
          min-width: 110px;
          font-size: 11px;
          &:hover {
            @include btn-style-hover();
          }
        }
      }
    }
  }
}
/* Home page email address scroll bar in ie11 */
.section-home {
  .landing-page {
    &__section {
      .signup-tout-wrapper {
        .signup-tout-content {
          -ms-overflow-y: hidden;
        }
      }
      .product-grid__content {
        .product-grid__item {
          .product-brief {
            @include breakpoint($medium-portrait-only) {
              min-height: 520px;
              position: relative;
            }
            @include breakpoint($medium-landscape-only) {
              min-height: 560px;
              position: relative;
            }
            @include breakpoint($tablet-only) {
              .product-brief__footer {
                position: absolute;
                bottom: 0;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

#samples {
  @include breakpoint($landscape-down) {
    .samples-page {
      .continue-buttons {
        float: right;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        margin-bottom: 10px;
        display: none;
        a.continue-checkout {
          text-transform: uppercase;
          width: 90%;
          font-size: 15px;
          border: 1px solid $color-white;
        }
      }
      .edit-cart {
        display: none;
      }
    }
    #samples-panel {
      .samples-buttons {
        .add-button {
          display: block;
          float: right;
          position: fixed;
          bottom: 0;
          z-index: 10;
          width: 95%;
          margin-bottom: 10px;
          border: 1px solid $color-white;
          border-radius: 4px;
        }
      }
      .btn {
        &.sample-select-button {
          font-size: 11px;
          min-width: 110px;
        }
      }
    }
  }
}

#sticky-checkout-button,
#bottom-viewcart-buttons,
.continue-buttons,
#samples-panel,
.samples-overlay {
  .btn {
    @include breakpoint($landscape-down) {
      @include btn-style();
      min-width: 140px;
      width: 100%;
      font-size: 15px;
      &:hover {
        @include btn-style-hover();
      }
    }
  }
}

.continue-buttons,
#bottom-viewcart-buttons {
  .btn {
    &.paypal-checkout {
      @include breakpoint($landscape-down) {
        width: 100%;
        padding: 6px 16px;
        margin-top: 12px;
        border: 1px solid $color-light-gray;
        background: $color-white;
        display: inline-block;
        height: 40%;
      }
    }
  }
}

.spp__container {
  .product-full__afterpay {
    .after-pay {
      &__rate {
        @include breakpoint($landscape-up) {
          text-align: left;
        }
      }
    }
  }
  .product-social {
    &__item {
      &--facebook {
        display: none;
      }
    }
  }
}

#ot-sdk-btn {
  &.ot-sdk-show-settings {
    font-size: 15px !important;
    color: $color-black !important;
    &:hover {
      color: $color-green !important;
    }
  }
}

.enable-vto-foundation {
  .LPMcontainer {
    pointer-events: none;
    visibility: hidden;
  }
}
